import styled from 'styled-components';

export const Button = styled.div`
  border-radius: 8px;
  padding: 12px 25px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  -webkit-appearance: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;

  a {
    text-decoration: none;
  }

  // SIZES

  &.large {
    font-weight: 700;
    font-size: 18px;
    min-width: 200px;
    padding: 15px;
  }

  &.medium {
    font-weight: 600;
    font-size: 16px;
    min-width: 180px;
    text-transform: uppercase;
  }

  &.small {
    font-weight: 700;
    font-size: 14px;
    min-width: 180px;
    text-transform: uppercase;
  }

  &.wide {
    width: 100%;
  }

  // COLORS

  &.blue {
    background: var(--color-blue-dark);
    color: #fff;

    a {
      color: #fff;
    }
  }

  &.lightBlue {
    background: #ecf1f6;
    color: #66727e;

    a {
      color: #66727e;
    }
  }

  &.yellow {
    background: var(--color-yellow);
    color: var(--color-blue);
  }

  &.green {
    background: var(--color-green);

    a {
      color: white;
    }
  }

  &.transparent {
    background: transparent;
    border: 1px solid #fff;
    height: 48px;
    line-height: 23px;
  }

  // ALIGNS

  &.center {
    display: table;
    margin: 50px auto 25px;
  }

  &.right {
    margin-left: auto;
    margin-right: unset;
  }

`;