/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components';
import Icon from "./Icon";
import { BottomDivider } from "./styles/Sections";
import { Text, Flex} from "./styles/Ui";

const Bio = ({ hideDescription, author, hideName }) => {

  const avatarUrl = author?.avatar?.url

  return (
    <Author>
      {avatarUrl && (
        <img
          alt={author?.name || ``}
          className="bio-avatar"
          src={avatarUrl}
          width="150"
          height="150"
          loading="lazy"
        />
      )}
      {author?.name && (
        <div>
          {!hideName &&
            <div>
              Skribent: 
              <Link to={author.uri} title={"Inlägg av " + author.name}><strong>{author.name}</strong></Link>
            </div>
          }
          {!hideDescription && (
            <p>
              {` `}
              {author?.description || null}
              {` `}
            </p>
          )}
          
          {author.authorSocials && author.authorSocials.linkedin && 
            <>
              <BottomDivider className="sm" />
              <Flex className="items-center gap-xs">
                <Text className="small">Sociala kanaler:</Text> <a href={author.authorSocials.linkedin} aria-label="linkedin" rel="noopener noreferrer nofollow" target="_blank"><Icon icon="linkedin" size={16} /></a>
              </Flex>
            </>
          }
        </div>
      )}
    </Author>
  )
}

export default Bio

const Author = styled.div`
  display: grid;
  grid-template-columns: .1fr 1fr;
  grid-gap: 25px;
  align-items: center;
  margin: 50px 0 50px;
  padding: 25px;
  border-radius: 12px;
  box-shadow: rgb(169 177 200 / 25%) 0px 10px 15px;
  border: 1px solid rgba(28, 46, 148, 0.1);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin: 25px 0 50px;
  }

  strong {
    font-size: var(--fontSize-1);
    margin-left: 5px;
  }

  p {
    font-size: 15px;
    margin: 5px 0 0;
  }

  img {
    border-radius: 100% !important;
    max-width: 80px;
    max-height: 80px;
  }

  a {
    color: var(--color-heading);
    text-decoration: none;
  }
`;
