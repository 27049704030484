import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Icon from "./Icon"

const BreadCrumbs = ({ items, align, modified, transparentBackground, noPadding, hideDate }) => {

  return (
    <DetailWrapper className={transparentBackground ? "no-bg" : undefined}>
      <PageDetails className={noPadding ? "no-padding" : undefined}>
        <BreadCrumb aria-label="breadcrumb" className={align}>
          <ol itemScope itemType="https://schema.org/BreadcrumbList">
            {items.map((item, i) => (
              <li key={i} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <Link to={item.url} itemProp="item">
                  <span itemProp="name">{item.text}</span>
                </Link>
                <meta itemProp="position" content={i + 1} />
              </li>
            ))}
          </ol>
        </BreadCrumb>
        {!hideDate && (
          <div className="modified"><Icon icon="note-checked" size={14} /> Senast uppdaterad: {modified}</div>
        )}
      </PageDetails>
    </DetailWrapper>
  )
}

export default BreadCrumbs;

const DetailWrapper = styled.div`
  background: var(--color-blue-dark);

  &.no-bg {
    background: transparent;
    color: #000 !important;

    a, .modified, li:after  {
      color: #000 !important;
    }
  }
`;

const PageDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  max-width: var(--container);
  margin: 0 auto;
  color: #f5f5f5;

  &.no-padding {
    padding: 0 0 25px;
  }

  .modified {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: var(--fontWeight-medium);
    margin-left: auto;
    text-transform: capitalize;

    @media (max-width: 600px) {
      display: none;
    }

    svg {
      font-size: 13px;
      margin-right: 5px;
    }
  }
`;


const BreadCrumb = styled.div`
  display: flex;

  &.center {
    justify-content: center;
  }

  ol {
    display: flex;
    align-items: center;
    margin: -5px 0 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;

    li {
      margin: 0;

      &:after {
        content: '-';
        position relative;
        margin: 0 7px;
        top: 2px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .separator {
        display: none;
      }

      a {
        color: #f5f5f5;
        text-decoration: none;
        font-size: 13px;
        font-weight: var(--fontWeight-medium);
      }

      &:last-child {
        a {
          font-weight: var(--fontWeight-semibold);
        }
      }
    }
  }
`;
