import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image"

const ArticleComponent = ({ item, isFrontPage, featured }) => {
  const featuredImage = {
    data: item.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.featuredImage?.node?.altText || ``,
  }

  const date = item.date;
  const author = item.author ? item.author.node : undefined;
  const avatarUrl = author && author.avatar?.url;
  const wrappedContent = isFrontPage ? "frontPageWrapper" : "contentWrapper";

  return (
    <Article>
      <Link to={item.uri}>
        {featuredImage?.data && !featured && (
          <GatsbyImage
            image={featuredImage.data}
            alt={featuredImage.alt}
            style={{ 
              height: 140,
              borderRadius: 4
            }}
          />
        )}
        {featured && (
          <GatsbyImage
            image={featuredImage.data}
            alt={featuredImage.alt}
            style={{ 
              height: 300,
              width: "100%",
              borderRadius: 4
            }}
          />
        )}
      </Link>
      
      <div className={wrappedContent}>
        <Link to={item.uri}>
          <div className="articleTitle">{item.title}</div>
          <div className="authorWrapper">
            {author && (
              <span>
                {avatarUrl && (
                  <img
                    alt={author?.name || ``}
                    className="bio-avatar"
                    src={avatarUrl}
                    width="150"
                    height="150"
                    loading="lazy"
                  />
                )}
                {author?.name}
              </span>
            )}
            {author && ( <span>•</span> )}
            {item?.date && ( <span>{date}</span> )}
          </div>
        </Link>

        {item?.excerpt && (
          <div>{parse(item.excerpt)}</div>
        )}
        
      </div>
      {}
      {/* <Link to={item.uri}>
        <Button className="green medium">Läs mer</Button>
      </Link> */}
    </Article>
  );
}

export default ArticleComponent;

const Article = styled.div`
  width: 100%;
  height: min-content;
  row-gap: 10px;
  border-radius: 8px;

  .contentWrapper {
    height: min-content;
    padding-top: 10px;
    row-gap: 10px;
    display: grid;
  }

  .frontPageWrapper {
    height: min-content;
    padding-top: 10px;
    row-gap: 10px;
    display: grid;

    .articleTitle, p {
      color: #f5f5f5;
    }

    .categories {
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }

  a {
    text-decoration: none;
  }

  img {
    border-radius: 4px !important;
  }

  h2 {
    margin: 0;
    font-size: var(--fontSize-2);
  }

  .articleTitle {
    margin: 0;
    font-size: 19px;
    color: var(--color-heading);
    font-weight: 600;
    font-family: var(--fontFamily-sans);
    line-height: 1.2;
    display: block;
    letter-spacing: -0.025em;
  }

  span {
    font-size: 14px;
    font-weight: var(--fontWeight-medium);
    color: var(--color-light);
  }

  .rating {
    display: flex;
    align-items: center;

    h2 {
      font-size: 18px;
    }

    span {
      margin-left: auto;
      margin-top: 6px;

      svg {
        font-size: 16px;
        color: var(--color-yellow);
      }
    }
  }

  .categories {
    display: flex;
    grid-gap: 5px;
    margin: 5px 0 0;
    flex-wrap: wrap;
 
    .category {
      border: 1px solid var(--color-blue);
      padding: 4px 8px;
      border-radius: 8px;
      color: var(--color-blue);
      font-size: 12px;
      font-weight: 600;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.3;
    font-weight: var(--fontWeight-medium);
    color: var(--color-text);
    margin: 5px 0 0;

    a {
      display: none;
    }
  }

  .authorWrapper {
    display: flex;
    grid-gap: 8px;
    align-items: center;
    margin-top: 10px;
    text-transform: capitalize;

    span {
      display: flex;
      align-items: center;
    }

    a {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }

    img {
      max-width: 16px;
      max-height: 16px;
      margin-right: 5px;
    }

    strong {
      font-size: 14px;
      font-weight: var(--fontWeight-medium);
      color: var(--color-heading);
    }
  }
`;