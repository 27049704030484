import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const ShareButtons = ({ url, title }) => {

  return (
    <Socials>
    {title ? <span>{title}</span> : <span>Dela inlägg:</span>}
    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + url}
    target="_blank" rel="nofollow noopener noreferrer" aria-label="Dela på Facebook"><Icon icon="facebook1" size={22} /></a>
    <a href={"https://twitter.com/intent/tweet?&url=" + url + ""} target="_blank" rel="nofollow noopener noreferrer" aria-label="Dela på Twitter"><Icon className="twitter" icon="twitter" size={22} /></a>
    </Socials>
  )
}

export default ShareButtons

export const Socials = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  grid-gap: 10px;

  span {
    display: block;
    font-weight: 500;
    font-size: 16px;
  }

  a {
    display: flex;
    align-items: center;
  }
`;
