import styled from 'styled-components';

export const Text = styled.div`
  color: var(--color-heading);

  /* FONT SIZES */

  &.center { text-align: center }
  &.small { font-size: 12px }
  &.medium { font-size: 14px }
  &.large { font-size: 16px }
  &.x-large { font-size: 18px }

  /* FONT WEIGHTS */

  &.medium { font-weight: 500 }
  &.semibold { font-weight: 600 }
  &.bold { font-weight: 700 }

  /* PADDINGS */
  
  &.padding-small { padding: 15px }
  &.padding-medium { padding: 20px }
  &.padding-large { padding: 25px }
`;

export const Box = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px -3px;

  .rating {
    svg {
      fill: #fc0 !important;
      margin: 0 1px;
    }
  }

  .center {
    margin: 0 auto 6px;
  }

  p {
    margin-bottom: 40px;
  }

  img {
    border-radius: 4px;
  }

  &.padding-small { padding: 15px }
  &.padding-medium { padding: 20px }
  &.padding-large { padding: 25px }
  &.padding-notop { padding: 0 25px 25px }
  &.is-in-slide {
    height: calc(100% - 10px);
  }
`;

export const Flex = styled.div`
  display: flex;
  
  &.items-center { align-items: center }
  &.flex-col { flex-direction: column }
  &.gap-xs { gap: 10px }
  &.gap-sm { gap: 15px }
  &.gap-md { gap: 25px }
  &.gap-lg { gap: 50px }
`

